import { createApp } from 'layout_congress/js/App.mts'
import { createApp as baseCreateApp } from 'vue'

import routes from 'signin/vue/page/signin/routes.mts'
import { createRouter, createWebHistory } from 'vue-router'

import { remoteContentPlugin } from 'layout_congress/plugins/remoteContentPlugin.mts'

const app = createApp({
    template: '<router-view />',
})

const router = createRouter({
  history: createWebHistory(),
  routes,
})
app.use(router)

app.use(remoteContentPlugin, { createApp: baseCreateApp, })

app.mount('#app')
