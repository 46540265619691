import { assignRootRoute } from 'layout_congress/js/urls.mts'
import { defineAsyncComponent } from 'vue'
import type { RouteRecordRaw } from 'vue-router'

const pageRoutes: RouteRecordRaw[] = [
    {
        name: 'login',
        path: '/:lang/accounts/login/',
        component: () => import('./LoginPage.vue'),
    },
    {
        name: 'admin-login',
        path: '/:lang/admin/login/',
        component: () => import('./LoginPage.vue'),
    },
]

const popupRoutes: RouteRecordRaw[] = [
  {
    name: 'bankid',
    path: 'bankid/',
    component: () => import('layout_congress/vue/forms/DjangoFormV2ModalWrapper.vue'),
    meta: {
      'defaultModalClass': null,
      'components': {
        BankIDJsonWidget: defineAsyncComponent(() => import('bank_id/vue/BankIDJsonWidget.vue')),
      },
      form: true,
    },
  }
]

export const routes = pageRoutes.map(route => {
  return {
    ...route,
    children: [
      ...(route.children ?? []),
      ...assignRootRoute(route,popupRoutes),
    ],
  } as RouteRecordRaw
})

export default routes
